<template>
    <div class="loader_area3" v-if="contactFileLoader"><quote-loader/></div>
    <div class="profile_notes" v-else>
        <div class="title">
            <h3>Files
                <button type="button" class="add_btn pointer ml-auto" @click="addFile = true"><i class="fas fa-plus"></i>Add File</button>
                <label :for="`switch-preview`" class="switch_option capsule_btn m-0 py-1 ml-3" v-if="files.total">
                    <h5 class="mr-2">Preview</h5>
                    <input type="checkbox" :id="`switch-preview`" v-model="previewImage" :true-value="1" :false-value="0" hidden>
                    <div><span></span></div>
                </label>
            </h3>
            <div class="right_side">
                <div class="search_panel">
                    <div class="form-field">
                        <input type="text" placeholder="Search" :disabled="contactFileLoader" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                    </div>
                    <button class="search_btn">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <page-filter v-model="params.per_page" />
                <button :class="selected.length ? 'delete active' : 'delete'" @click="handleDelete">
                    <i class="fas fa-trash-alt"></i>
                </button>
            </div>
        </div>
        <div class="notes_wpr">
            <ul class="card_listing" v-if="files.total">
                <li v-for="(file, f) of files.data" :key="f">
                    <profile-card v-bind:actions="actions" :action-handler="actionHandler" :selected-row="file">
                        <div v-if="previewImage">
                            <img :src="file.file.thumbnail" />
                        </div>
                        <label :for="`file-${f}`" class="checkbox">
                            <input type="checkbox" :id="`file-${f}`" :value="file.file_id" v-model="selected" hidden>
                            <span><i class="fas fa-check"></i></span>
                        </label>
                        <h4 class="pointer mb-2" @click="viewFile(file)"><img src="@/assets/images/picture-alt.svg">{{ file.file.name.length > 25 ? file.file.name.substring(0, 25) + '...' : file.file.name }}</h4>
                        <label :for="`switch-${f}`" class="switch_option capsule_btn mb-5">
                            <h5>Show in Portal</h5>
                            <input type="checkbox" :id="`switch-${f}`" @change="handlePortalPermission($event, file)" :checked="file.client_portal == 1" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <div class="card_footer">
                            <!-- <h5>Created on:</h5> -->
                            <span class="date">
                                {{ moment.utc(file.created_at).local().format('ll | LT') }}
                            </span>
                        </div>
                    </profile-card>
                </li>
            </ul>
            <div class="empty_list mt-3" v-if="files.total == 0 && !contactFileLoader">
                <img src="@/assets/images/empty_state.svg">
                <h4>No Records Found!</h4>
            </div>
        </div>
        <div class="pagination" v-show="files.total">
            <pagination v-model="params.page" :pages="files.last_page" @update:modelValue="handlePagination" />
        </div>
    </div>
    <div v-if="view" class="edit_modal" @click.self="view = false">
        <div class="edit_section p-0">
            <div class="form_body">
                <h2 class="title_header"><div class="title">{{ selectedFile.file.name }}</div><button class="close_btn" @click="view = false"><i class="fas fa-times"></i></button></h2>
                <div class="form_content"><file-preview :file="selectedFile.file" /></div>
            </div>
        </div>
    </div>
    <div v-if="addFile" class="edit_modal add_file" @click.self="addFile = false">
        <div class="edit_section setting_wpr p-0">
            <div class="form_body">
                <h2 class="title_header">Add Files<button class="close_btn" @click="addFile = false"><i class="fas fa-times"></i></button></h2>
                <Form @submit="handleFileAdd" v-slot="{ errors }" ref="profile-send-file-form">
                    <div class="form_content file_modal">
                        <file-component :is-list="false" :errors="errors" :contacts="contact.id" ref="file-component" />
                        <div class="action_wpr mt-5">
                            <button type="button" :disabled="fileLoader" class="btn cancel_btn" @click="addFile = false">Cancel</button>
                            <button :disabled="fileLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="fileLoader"></i> {{ fileLoader ? 'Adding' : 'Add' }}</button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Form } from 'vee-validate'
    import { copyText } from 'vue3-clipboard'
    import { mapState, mapActions } from 'vuex'

    const ProfileCard = defineAsyncComponent(() => import('@/pages/contact/components/profile/ProfileCard'))
    const FilePreview = defineAsyncComponent(() => import('@/components/FilePreview'))
    const FileComponent = defineAsyncComponent(() => import('@/components/FileComponent'))

    import moment from 'moment'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Tab File',

        data () {
            return {
                actions: [
                    {
                        icon: "fa-link",
                        label: "Copy",
                        action: 'copyLink'
                    },
                    {
                        icon: "fa-search-plus",
                        label: "View",
                        action: 'viewFile'
                    },
                    {
                        icon: "fa-download",
                        label: "Download",
                        action: 'handleDownloadFile'
                    },
                ],
                params: {
                    page: 1,
                    per_page: 6,
                    contact_id: '',
                    search: '',
                    all: false,
                },
                moment,
                isTyping: false,
                view: false,
                selectedFile: {},
                selected: [],
                previewImage: 0,
                addFile: false,
            };
        },

        props: {
            contact: Object,
        },

        components: {
            Form,
            ProfileCard,
            FilePreview,
            FileComponent,
        },

        watch: {
            'params.per_page' () {
                const vm = this;

                vm.getContactFiles(vm.params);
            },

            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getContactFiles(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getContactFiles(vm.params);
                        }
                    }
                }
            },

            files () {
                const vm = this;
                vm.selected = [];
            }
        },

        computed: mapState({
            fileLoader: state => state.fileModule.fileLoader,
            contactFileLoader: state => state.fileModule.contactFileLoader,
            files: state => state.fileModule.contactFiles,
        }),

        mounted () {
            const vm = this;

            vm.params.contact_id = vm.contact.id;
            vm.getContactFiles(vm.params);
        },

        methods: {
            ...mapActions({
                getContactFiles: 'fileModule/getContactFiles',
                deleteContactFiles: 'fileModule/deleteContactFiles',
                updatePermission: 'fileModule/updatePermission',
                downloadFile: 'fileModule/downloadFile',
            }),

            actionHandler (file, action) {
                const vm = this;

                vm[action](file);
            },

            copyLink (file) {
                const vm = this;

                vm.view = false;
                vm.selectedFile = file;

                copyText(file.file.short_link, undefined, (error, event) => {
                    if (error) {
                        Toastr.error('Failed to copy file link!');
                    } else {
                        Toastr.success('File link copied successfully!');
                    }
                })
            },

            viewFile (file) {
                const vm = this;

                vm.view = true;
                vm.selectedFile = file;
            },

            handleDownloadFile (file) {
                const vm = this;

                vm.view = false;
                vm.selectedFile = file;
                vm.downloadFile(file.file);
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getContactFiles(vm.params);
            },

            handleDelete () {
                const vm = this;
                const ids = JSON.parse(JSON.stringify(vm.selected));

                const option = Helper.swalConfirmOptions('Are you sure?', "This action is permanent. You will not be able to recover these files.", 'Delete')
                      option.preConfirm = function() {
                                              return vm.deleteContactFiles({ ids, contact_ids: [vm.contact.id] }).then((result) => {
                                                  if (result) {
                                                      vm.selected = [];
                                                      vm.getContactFiles(vm.params);
                                                  }
                                              });
                                          };

                Swal.fire(option);
            },

            handlePortalPermission (event, file) {
                const vm = this;
                const value = event.currentTarget.checked ? 1 : 0;

                vm.updatePermission({ permission: 'client_portal', value, id: file.file_id, contact_ids: [file.contact_id] });
            },

            handleFileAdd () {
                const vm = this;
                const fileComponent = vm.$refs['file-component'];

                fileComponent.handleFileAdd().then((result) => {
                    if (result) {
                        vm.getContactFiles(vm.params);
                    }
                });
            },
        },
    }
</script>

<style scoped>
    .notes_wpr{
        overflow-y: scroll;
        flex: 1;
    }
    .notes_wpr::-webkit-scrollbar {
        display: none;
    }
    .profile_notes .title h3{
        align-items: center;
        flex-grow: 1;
    }
    .file_detailsWpr{
        max-height: none;
        padding: 0;
        margin: 0 -15px;
    }
    .edit_modal .edit_section{
        height: auto;
        max-width: 900px;
        margin: 0 auto;
    }
    .file_modal{
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
    /* .edit_modal .edit_section .form_body .form_content{
        padding: 30px;
        max-height: 566px;
        height: auto;
    } */
    .file_modal .action_wpr{
        position: relative;
        margin: auto -30px -30px -30px;
        padding: 20px 20px;
        background: #fff;
        border-top: 1px solid #e9e9e9;
    }
    .profile_notes .card_listing{
        max-height: calc(100vh - 228px);
        overflow-y: scroll;
    }
    .profile_notes .card_listing::-webkit-scrollbar {
        display: none;
    }
    .edit_modal.add_file .edit_section{
        max-width: 500px;
    }
    .edit_modal .edit_section .title_header .title{
        padding-right: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }
    @media(max-width: 1350px){
        .profile_notes .card_listing li{
            width: 33.333%;
        }
    }
    @media(max-width: 991px){
        .profile_notes .card_listing li{
            width: 50%;
        }
    }
    @media(max-width: 600px){
        .profile_notes .card_listing li{
            flex: 0 0 100%;
        }
        .profile_notes .title{
            flex-direction: column;
            align-items: flex-start;
            row-gap: 10px;
        }
        .edit_modal{
            padding: 10px !important;
        }
        .profile_notes .title{
            flex-wrap: wrap;
        }
        .profile_notes .title h3{
            width: 100%;
            margin-bottom: 15px;
        }
        .profile_notes .title .right_side{
            width: 100%;
        }
        .profile_notes .title .right_side .search_panel{
            margin: 0 auto 0 0;
        }
    }
    @media(max-width: 499px){
        .file_detailsWpr{
            padding-top: 15px;
            padding-bottom: 30px;
        }
    }
</style>
